
.mainContainer {
    padding-right: 30px;
    padding-left: 30px;
    height: 110vh;
}

.titleContainer {
    padding-top: 2vw;
    padding-bottom: 2vw;
    font-size: 40px;
    color: #E2352B;
    align-items: center;
    text-align: center;
    p {
      font-size: 45px;
      font-family: 'Montserrat', sans-serif;
    }
}

.imageContainer {
    width: 8vw;
}

.mobileImage {
  width: 20vw;
}

.mainText {
    color: #E2352B;
    font-size: 30px;
    font-weight: bold;
    display: inline;
    margin: 0px;
    font-family: 'Montserrat', sans-serif;

}

.helperText {
    color: #6E727A;
    font-size: 16px;
    font-weight: 30;
    display: inline;
}

.row {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  width: 100%;
}

.workContainer {
  display: flex;
  width: 20em;
  margin-right: 20px;
  margin-bottom: 30px;
}

.column {
  display: flex;
  flex-direction: column;
  flex-basis: 100%;
  flex: 1;
  margin-right: 10px;
  margin-left: 10px;
}

.textColumn {
  display: flex;
  flex-direction: column;
  min-width: 10vw;
  margin-right: 40px;
  flex-basis: 100%;
  flex: 1;
  margin-right: 10px;
  margin-left: 10px;
}

.mobileColumn {
  width: 60%;
  display: flex;
  flex-direction: column;
  height: 50px;
}
