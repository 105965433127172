.root {
    line-height: 1.5;
}

.close-button {
    position: absolute;
    top: 10px;
    right: 10px;
    color: white;
    border: 1px solid #e1352b;
    background-color: #e1352b;
    border-radius: 10px;
    font-size: 15px;
    padding: 5px 10px;
}

.close-button:hover {
    background-color: white;
    color: #e1352b;
    cursor: pointer;
}
