
.mainContainer {
  height: 12vh;
  background-color: black;
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.rightTextContainer {
  display: flex;
  width: 40vh;
  p {
    b {
      color: #E1352B;
      padding-right: 5px;
    }
    font-size: 12px;
    color: white;
    font-family: 'Montserrat', sans-serif;
  }
}
