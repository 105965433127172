
.mainContainer {
  height: 180vh;
  background-color: #ecf1eb;
  display: flex;
  flex-direction: column;
}


.jumbotronContainer {
  width: auto;
  height: 90vh;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
}


.lowerHalf {
  width: auto;
  height: 90vh;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  background-image: url('../../assets/website_bg_bot.png');
  background-size: cover;
  background-blend-mode: multiply;
}

.rewardsText {
  padding-top: 30px;
  height: 10vh;
  p {
    font-size: 36px;
    font-family: 'Montserrat', sans-serif;
    color: #ff5b07;
  }
}

.helperTextSection {
  width: 90vw;
  display: flex;
  align-self: center;
  margin: 2vw;
  padding: 2vw;
  justify-content: space-between;
}

.textHolder {
  width: 40vw;
  margin: 2vw;
  height: 20vh;
  p {
    font-size: 14px;
    color: #6A6C6C;
    font-family: 'Montserrat', sans-serif;
  }
}

.downloadText {
  width: 90vw;
  justify-content: center;
  display: flex;
  p {
    font-size: 24px;
    color: white;
    font-family: 'Montserrat', sans-serif;
  }
}

.helpText {
  p {
    font-size: 12px;
    color: white;
    font-family: 'Montserrat', sans-serif;
  }
}


.linksContainer {
  width: 90vw;
  display: flex;
  justify-content: center;
  align-items: center;

  img {
    padding-left: 10px;
    padding-right: 10px;
    padding-bottom: 30px;
    padding-top: 10px;
    max-height: 40px;
    height: 12vw;
    width: auto;
  }
}

.vuewinLogoContainer {
  margin-top: 20vh;
  img {
    height: 10vh;
    width: auto;
  }
}

.followContainer {
  width: 80vh;
  display: flex;
  justify-content: center;
  align-items: center;

  img {
    padding: 15px;
    height: 50px;
    width: auto;
  }
}

.carImageContainer {
  width: 100%;
  position: absolute;
  z-index: 1;
  top: 260vh;
  img {
    width: 100%;
    height: auto;
    mix-blend-mode: multiply;
  }
}

.downloadButton:hover {
  cursor: pointer;
}

.mobileCarImagecontainer {
  width: 100%;
  position: absolute;
  z-index: 1;
  top: 515vw;
  left: -10vw;
  img {
    width: 120vw;
    height: auto;
    mix-blend-mode: multiply;
  }
}
