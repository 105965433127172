html {
  height: 100%;
  width: 100vw;
}

body {
  margin: 0;
  padding: 0;
  width: 100vw;
  height: 100%;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.Home_mainApp__QRanm {
  width: 100%;
  height: 100%;
  overflow-x: hidden; }

.NavHeader_navBar__1nEZZ {
  position: fixed;
  top: 0;
  left: 0;
  height: 70px;
  width: 100vw;
  -webkit-align-items: center;
          align-items: center;
  background-color: #e1352b;
  vertical-align: baseline;
  z-index: 2; }

.NavHeader_navBar__1nEZZ nav {
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: space-between;
          justify-content: space-between;
  margin: auto;
  height: 100%;
  -webkit-align-items: center;
          align-items: center;
  -webkit-align-self: center;
          align-self: center;
  z-index: 2; }

navbar-fixed-top {
  position: fixed; }

.NavHeader_navBarMobile__1RiGh {
  height: 10vh;
  width: 100%;
  background-color: #e1352b;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: space-between;
          justify-content: space-between;
  -webkit-align-items: center;
          align-items: center;
  position: fixed;
  top: 0;
  z-index: 2; }

.NavHeader_logoNavBar__GzB-b {
  height: 40px;
  width: auto;
  padding-top: 5px;
  margin-left: 15px;
  margin-right: 40px; }

.NavHeader_navBarLinksContainer__1t_xH {
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  margin-top: 2px;
  margin-right: 30px;
  padding: 0px; }
  .NavHeader_navBarLinksContainer__1t_xH p {
    color: white;
    font-size: 17px;
    font-family: 'Montserrat', sans-serif;
    margin-right: 20px;
    white-space: nowrap; }

.NavHeader_getThisAppIcon__25RAd {
  height: 35px;
  width: auto; }

.NavHeader_getThisAppIcon__25RAd:hover {
  cursor: pointer; }

.NavHeader_navButton__3Yxjg {
  padding: 10;
  height: 100%;
  border: none;
  -webkit-align-items: center;
          align-items: center;
  font: inherit;
  color: inherit;
  cursor: pointer;
  background-color: transparent; }

.NavHeader_logoSidebarContainer__a40oc {
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center; }

.NavHeader_sidebarButton__3CI6o {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  width: 25px;
  height: 20px;
  margin-left: 10px;
  -webkit-justify-content: space-between;
          justify-content: space-between; }

.NavHeader_sidebarButton__3CI6o:hover {
  cursor: pointer; }

.NavHeader_sideBarButtonRow__2_xGS {
  background-color: white;
  height: 2px;
  width: 100%; }

.NavHeader_navButton__3Yxjg:hover p {
  font-weight: bold; }

.NavHeader_sidebar__1Pdct {
  height: 100%;
  width: 30vh;
  min-width: 200px;
  background-color: #fff5ee;
  position: fixed;
  top: 0;
  left: 0; }
  .NavHeader_sidebar__1Pdct p {
    color: #e1352b; }

.NavHeader_closeSidebarContainer__1l1Ob {
  width: 100%;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row-reverse;
          flex-direction: row-reverse;
  height: 40px;
  -webkit-align-items: center;
          align-items: center;
  margin-right: 10px; }

.NavHeader_closeIcon__1w1bn {
  width: 30px;
  margin: 5px; }

.NavHeader_closeIcon__1w1bn:hover {
  cursor: pointer; }

.NavHeader_documentList__1bEqR {
  display: -webkit-flex;
  display: flex;
  margin-top: 10px;
  -webkit-flex-direction: column;
          flex-direction: column;
  padding-left: 10px; }

.HomeTopSection_mainContainer__3HsfR {
  height: 90vh;
  width: 100%;
  background-color: #ff5b07;
  margin-top: 70px; }

.HomeTopSection_jumbotronContainer__2EMr5 {
  width: auto;
  height: 100%;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: center;
          justify-content: center;
  background-image: url(/static/media/website_bg_top.a2d7acda.png);
  background-size: cover; }
  .HomeTopSection_jumbotronContainer__2EMr5 p {
    font-size: 45px;
    font-family: 'Montserrat', sans-serif; }

.HomeTopSection_mainText__2NhG5 {
  width: 90vw;
  margin-top: 0px;
  display: -webkit-flex;
  display: flex;
  padding-left: 30px;
  padding-right: 10px;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: center;
          justify-content: center; }
  .HomeTopSection_mainText__2NhG5 p {
    font-size: 40px;
    color: white; }

.HomeTopSection_helperText__7aYzc {
  width: 90vw;
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: center;
          justify-content: center; }
  .HomeTopSection_helperText__7aYzc p {
    font-size: 14px;
    color: white;
    font-family: 'Montserrat', sans-serif; }

.HomeTopSection_downloadsContainer__2pHnf {
  width: 90vw;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-align-items: center;
          align-items: center;
  margin-top: 20px; }
  .HomeTopSection_downloadsContainer__2pHnf img {
    padding: 2vw;
    min-height: 40px;
    height: 2.5vw;
    width: auto; }

.HomeTopSection_downloadButton__NtL1h:hover {
  cursor: pointer; }

.HomeMidSection_mainContainer__1PzbM {
  padding-right: 30px;
  padding-left: 30px;
  height: 110vh; }

.HomeMidSection_titleContainer__p3Ffa {
  padding-top: 2vw;
  padding-bottom: 2vw;
  font-size: 40px;
  color: #E2352B;
  -webkit-align-items: center;
          align-items: center;
  text-align: center; }
  .HomeMidSection_titleContainer__p3Ffa p {
    font-size: 45px;
    font-family: 'Montserrat', sans-serif; }

.HomeMidSection_imageContainer__SiRF1 {
  width: 8vw; }

.HomeMidSection_mobileImage__2vhL8 {
  width: 20vw; }

.HomeMidSection_mainText__RXv9t {
  color: #E2352B;
  font-size: 30px;
  font-weight: bold;
  display: inline;
  margin: 0px;
  font-family: 'Montserrat', sans-serif; }

.HomeMidSection_helperText__1fJWB {
  color: #6E727A;
  font-size: 16px;
  font-weight: 30;
  display: inline; }

.HomeMidSection_row__w8lpY {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row;
          flex-direction: row;
  -webkit-flex-wrap: wrap;
          flex-wrap: wrap;
  -webkit-justify-content: center;
          justify-content: center;
  width: 100%; }

.HomeMidSection_workContainer__l72Cr {
  display: -webkit-flex;
  display: flex;
  width: 20em;
  margin-right: 20px;
  margin-bottom: 30px; }

.HomeMidSection_column__3dhO- {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-flex-basis: 100%;
          flex-basis: 100%;
  -webkit-flex: 1 1;
          flex: 1 1;
  margin-right: 10px;
  margin-left: 10px; }

.HomeMidSection_textColumn__2Fo2r {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  min-width: 10vw;
  margin-right: 40px;
  -webkit-flex-basis: 100%;
          flex-basis: 100%;
  -webkit-flex: 1 1;
          flex: 1 1;
  margin-right: 10px;
  margin-left: 10px; }

.HomeMidSection_mobileColumn__1-cL8 {
  width: 60%;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  height: 50px; }

.RewardsSection_mainContainer__38PNj {
  height: 180vh;
  background-color: #ecf1eb;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column; }

.RewardsSection_jumbotronContainer___0nxE {
  width: auto;
  height: 90vh;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-justify-content: flex-start;
          justify-content: flex-start;
  -webkit-align-items: center;
          align-items: center; }

.RewardsSection_lowerHalf__3cAZI {
  width: auto;
  height: 90vh;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-justify-content: flex-start;
          justify-content: flex-start;
  -webkit-align-items: center;
          align-items: center;
  background-image: url(/static/media/website_bg_bot.55799131.png);
  background-size: cover;
  background-blend-mode: multiply; }

.RewardsSection_rewardsText__1kE7T {
  padding-top: 30px;
  height: 10vh; }
  .RewardsSection_rewardsText__1kE7T p {
    font-size: 36px;
    font-family: 'Montserrat', sans-serif;
    color: #ff5b07; }

.RewardsSection_helperTextSection__2ZSmo {
  width: 90vw;
  display: -webkit-flex;
  display: flex;
  -webkit-align-self: center;
          align-self: center;
  margin: 2vw;
  padding: 2vw;
  -webkit-justify-content: space-between;
          justify-content: space-between; }

.RewardsSection_textHolder__45x_o {
  width: 40vw;
  margin: 2vw;
  height: 20vh; }
  .RewardsSection_textHolder__45x_o p {
    font-size: 14px;
    color: #6A6C6C;
    font-family: 'Montserrat', sans-serif; }

.RewardsSection_downloadText__3HMio {
  width: 90vw;
  -webkit-justify-content: center;
          justify-content: center;
  display: -webkit-flex;
  display: flex; }
  .RewardsSection_downloadText__3HMio p {
    font-size: 24px;
    color: white;
    font-family: 'Montserrat', sans-serif; }

.RewardsSection_helpText__bH_8K p {
  font-size: 12px;
  color: white;
  font-family: 'Montserrat', sans-serif; }

.RewardsSection_linksContainer__qQBPq {
  width: 90vw;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-align-items: center;
          align-items: center; }
  .RewardsSection_linksContainer__qQBPq img {
    padding-left: 10px;
    padding-right: 10px;
    padding-bottom: 30px;
    padding-top: 10px;
    max-height: 40px;
    height: 12vw;
    width: auto; }

.RewardsSection_vuewinLogoContainer__TIJSp {
  margin-top: 20vh; }
  .RewardsSection_vuewinLogoContainer__TIJSp img {
    height: 10vh;
    width: auto; }

.RewardsSection_followContainer__SFWcl {
  width: 80vh;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-align-items: center;
          align-items: center; }
  .RewardsSection_followContainer__SFWcl img {
    padding: 15px;
    height: 50px;
    width: auto; }

.RewardsSection_carImageContainer__w89vq {
  width: 100%;
  position: absolute;
  z-index: 1;
  top: 260vh; }
  .RewardsSection_carImageContainer__w89vq img {
    width: 100%;
    height: auto;
    mix-blend-mode: multiply; }

.RewardsSection_downloadButton__2-AyK:hover {
  cursor: pointer; }

.RewardsSection_mobileCarImagecontainer__2qpGp {
  width: 100%;
  position: absolute;
  z-index: 1;
  top: 515vw;
  left: -10vw; }
  .RewardsSection_mobileCarImagecontainer__2qpGp img {
    width: 120vw;
    height: auto;
    mix-blend-mode: multiply; }

.Footer_mainContainer__9DHh8 {
  height: 12vh;
  background-color: black;
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: flex-end;
          justify-content: flex-end; }

.Footer_rightTextContainer__1ogpM {
  display: -webkit-flex;
  display: flex;
  width: 40vh; }
  .Footer_rightTextContainer__1ogpM p {
    font-size: 12px;
    color: white;
    font-family: 'Montserrat', sans-serif; }
    .Footer_rightTextContainer__1ogpM p b {
      color: #E1352B;
      padding-right: 5px; }

.BetaModal_root__ONB9j {
    line-height: 1.5;
}

.BetaModal_close-button__354iW {
    position: absolute;
    top: 10px;
    right: 10px;
    color: white;
    border: 1px solid #e1352b;
    background-color: #e1352b;
    border-radius: 10px;
    font-size: 15px;
    padding: 5px 10px;
}

.BetaModal_close-button__354iW:hover {
    background-color: white;
    color: #e1352b;
    cursor: pointer;
}

.PrivacyPolicyPage_root__jm38a {
    line-height: 1.5;
    margin: 4vh;
}

.PrivacyPolicyPage_close-button__1cEyS {
    position: absolute;
    top: 10px;
    right: 10px;
    color: white;
    border: 1px solid #e1352b;
    background-color: #e1352b;
    border-radius: 10px;
    font-size: 15px;
    padding: 5px 10px;
}

.PrivacyPolicyPage_close-button__1cEyS:hover {
    background-color: white;
    color: #e1352b;
    cursor: pointer;
}

.FaqPage_root__IOTSz {
  line-height: 1.5;
  margin: 4vh; }

.FaqPage_close-button__1RnbY {
  position: absolute;
  top: 10px;
  right: 10px;
  color: white;
  border: 1px solid #e1352b;
  background-color: #e1352b;
  border-radius: 10px;
  font-size: 15px;
  padding: 5px 10px; }

.FaqPage_close-button__1RnbY:hover {
  background-color: white;
  color: #e1352b;
  cursor: pointer; }

.FaqPage_redText__1XAAp {
  color: red; }

.FaqPage_table__TmnER {
  width: 50%;
  margin: 3vh;
  border-collapse: collapse;
  border: 1px solid black; }
  .FaqPage_table__TmnER tr {
    border: 1px solid black; }
  .FaqPage_table__TmnER td {
    padding: 10px;
    border: 1px solid black;
    max-width: 40vh; }
  .FaqPage_table__TmnER th {
    padding: 10px;
    border: 1px solid black; }

.FaqPage_icon__3mVR6 {
  width: 5vh;
  -webkit-align-self: center;
          align-self: center;
  height: 5vh; }

.FaqPage_screenshot__3qwp0 {
  width: 80%;
  max-width: 40vh; }

.Preregistration_root__2Clec {
  line-height: 1.5;
  margin: 4vh; }

.Preregistration_screenshot__1c-w4 {
  width: 80%;
  max-width: 40vh; }

