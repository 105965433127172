.navBar {
  position: fixed;
  top: 0;
  left: 0;
  height: 70px;
  width: 100vw;
  align-items: center;
  background-color: #e1352b;
  vertical-align: baseline;
  z-index: 2;
}

.navBar nav {
  display: flex;
  justify-content: space-between;
  margin: auto;
  height: 100%;
  align-items: center;
  align-self: center;
  z-index: 2;
}

navbar-fixed-top {
  position: fixed;
}

.navBarMobile {
  height: 10vh;
  width: 100%;
  background-color: #e1352b;
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: fixed;
  top: 0;
  z-index: 2;
}

.logoNavBar {
  height: 40px;
  width: auto;
  padding-top: 5px;
  margin-left: 15px;
  margin-right: 40px;
}

.navBarLinksContainer {
  display: flex;
  align-items: center;
  margin-top: 2px;
  margin-right: 30px;
  padding: 0px;
  p {
    color: white;
    font-size: 17px;
    font-family: 'Montserrat', sans-serif;
    margin-right: 20px;
    white-space: nowrap;
  }
}

.getThisAppIcon {
  height: 35px;
  width: auto;
}

.getThisAppIcon:hover {
  cursor: pointer;
}

.navButton {
  padding: 10;
  height: 100%;
  border: none;
  align-items: center;
  font: inherit;
  color: inherit;
  cursor: pointer;
  background-color: transparent;
}

.logoSidebarContainer {
  display: flex;
  align-items: center;
}


.sidebarButton {
  display: flex;
  flex-direction: column;
  width: 25px;
  height: 20px;
  margin-left: 10px;
  justify-content: space-between;
}

.sidebarButton:hover {
  cursor: pointer;
}

.sideBarButtonRow {
  background-color: white;
  height: 2px;
  width: 100%;
}

.navButton:hover {
  p {
    font-weight: bold;
  }
}

.sidebar {
  height: 100%;
  width: 30vh;
  min-width: 200px;
  background-color: #fff5ee;
  position: fixed;
  top: 0;
  left: 0;
  p {
    color: #e1352b;
  }
}

.closeSidebarContainer {
  width: 100%;
  display: flex;
  flex-direction: row-reverse;
  height: 40px;
  align-items: center;
  margin-right: 10px;
}

.closeIcon {
  width: 30px;
  margin: 5px;
}

.closeIcon:hover {
  cursor: pointer;
}

.documentList {
  display: flex;
  margin-top: 10px;
  flex-direction: column;
  padding-left: 10px;
}
