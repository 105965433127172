
.mainContainer {
  height: 90vh;
  width: 100%;
  background-color: #ff5b07;
  margin-top: 70px;
}


.jumbotronContainer {
  width: auto;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-image: url('../../assets/website_bg_top.png');
  background-size: cover;
  p {
    font-size: 45px;
    font-family: 'Montserrat', sans-serif;
  }
}

.mainText {
  width: 90vw;
  margin-top: 0px;
  display: flex;
  padding-left: 30px;
  padding-right: 10px;
  align-items: center;
  justify-content: center;
  p {
    font-size: 40px;
    color: white;
  }
}

.helperText {
  width: 90vw;
  display: flex;
  align-items: center;
  justify-content: center;
  p {
    font-size: 14px;
    color: white;
    font-family: 'Montserrat', sans-serif;
  }
}


.downloadsContainer {
  width: 90vw;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 20px;
  img {
    padding: 2vw;
    min-height: 40px;
    height: 2.5vw;
    width: auto;
  }
}

.downloadButton:hover {
  cursor: pointer;
}
