.root {
    line-height: 1.5;
    margin: 4vh;
}

.close-button {
    position: absolute;
    top: 10px;
    right: 10px;
    color: white;
    border: 1px solid #e1352b;
    background-color: #e1352b;
    border-radius: 10px;
    font-size: 15px;
    padding: 5px 10px;
}

.close-button:hover {
    background-color: white;
    color: #e1352b;
    cursor: pointer;
}

.redText {
  color: red;
}

.table {
  width: 50%;
  margin: 3vh;
  border-collapse: collapse;
  border: 1px solid black;
  tr {
    border: 1px solid black;
  }
  td {
    padding: 10px;
    border: 1px solid black;
    max-width: 40vh;
  }
  th {
    padding: 10px;
    border: 1px solid black;
  }
}

.icon {
  width: 5vh;
  align-self: center;
  height: 5vh;
}

.screenshot {
  width: 80%;
  max-width: 40vh;
}
